@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Poppins", sans-serif;
}

html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  background: url("./assets/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  overflow: hidden;
  overflow-y: initial !important;
}